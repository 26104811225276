<template>
  <v-app>
    <!--  Application Bar-->
    <slot name="appBar">
      <external-app-bar></external-app-bar>
    </slot>

    <!-- Application Left Drawer-->
    <slot name="navigationLeftDrawer"></slot>

    <!-- Application Main -->
    <v-main>
      <slot name="main">
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </slot>
    </v-main>

    <!-- Navigation Right Drawer -->
    <slot name="navigationRightDrawer"></slot>

    <!-- Application Footer -->
    <slot name="footer">
      <app-footer></app-footer>
    </slot>

    <!-- Bottom Navigation -->
    <slot name="bottomNavigation"></slot>
  </v-app>
</template>

<script>
export default {
  name: "ExternalAppLayout",
  components: {
    ExternalAppBar: () =>
      import("@/components/shared/core/bars/ExternalAppBar"),
    AppFooter: () => import("@/components/shared/core/footers/AppFooter")
  }
};
</script>
